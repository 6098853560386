import type {
  SearchGroup,
  SearchResult,
} from './types.js'

const groups_ = {
  symptomsFresh: {
    name: '',
  },
  symptoms: {
    name: 'Symptoms',
    title: result => result.description ?? '',
    description: 'Continue exploring your symptoms.',
  },
  search: {
    name: 'Search Results',
  },
  assessment: {
    name: 'Assessments',
  },
  condition: {
    name: 'Health Conditions',
  },
  explore: {
    name: 'Explore',
  },
} satisfies Record<string, SearchGroup>

export const groups = groups_ as { [K in keyof typeof groups_]: SearchGroup }
export const groupPositions = Object.keys(groups).toObject((name, index) => [name, index])


export const defaultResults: SearchResult[] = [
  {
    id: '~symptomChecker',
    group: 'explore',
    title: 'Symptom Checker',
    description: 'Explore your symptoms.',
    url: '/symptom-checker',
    score: 1,
    icon: {
      name: 'check-circle',
      className: 'text-blue-500',
    },
  },
  {
    id: '~healthConditions',
    group: 'explore',
    title: 'Health Conditions',
    description: 'Discover treatments and medications, and prepare for your doctor visit.',
    url: '/health-conditions',
    score: 1,
    icon: {
      name: 'activity',
      className: 'text-rose-500',
    },
  },
]