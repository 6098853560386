import Mousetrap, { type ExtendedKeyboardEvent } from 'mousetrap'
import { getActiveElement } from '#/dom/focus'
import { isInteractiveInput } from '#/dom/capabilities'


if (Mousetrap?.prototype) {
  Mousetrap.prototype.stopCallback = noopFalse
}

type Key = string
type Fn = (event: ExtendedKeyboardEvent) => void
type Binding = Options & { key: Key, fn: Fn }

type Options = {
  enabled: boolean
  repeatable: boolean
  interactive: boolean
}


const defaultOptions: Options = {
  enabled: true,
  repeatable: false,
  interactive: false,
}


const [HotKeyContext, useHotKeyContext] = createRequiredContext<(_bindings: Binding[]) => () => void>('HotKeyContext')


export default function useHotKey(keys: Many<Key>, fn: Fn, options: Partial<Options> = Object._) {
  const bind = useHotKeyContext()
  fn = useEvent(fn)

  const bindings = useMemoObject(Array.cast(keys).map(key =>
    Object.defaults({ key, fn },
      options,
      defaultOptions,
    ),
  ))

  useEffect(() => bind(bindings), [bind, bindings])
}



export function HotKeyProvider({ children }: { children: React.ReactNode }) {
  const box = useBox({
    bindings: {} as Record<Key, Binding[] | null>,
  })

  const bind = useCallback((bindings: Binding[]) => {
    bindings.forEach(binding => {
      const { key } = binding

      if (!box.bindings[key]) {
        box.bindings[key] = []
        Mousetrap.bind(key, (event: ExtendedKeyboardEvent) => {
          handleKey(event, box.bindings[key]?.last())
        })
      }

      box.bindings[key].push(binding)
    })

    return () => {
      bindings.forEach(binding => {
        const { key } = binding
        const bindings_ = box.bindings[key]

        if (bindings_) {
          bindings_.pull(binding)

          if (bindings_.length === 0) {
            box.bindings[key] = null
            Mousetrap.unbind(key)
          }
        }
      })
    }
  }, [box])


  return (
    <HotKeyContext.Provider value={bind}>
      {children}
    </HotKeyContext.Provider>
  )
}



function handleKey(event: ExtendedKeyboardEvent, binding?: Binding) {
  if (!binding) {
    return
  }

  const {
    fn,
    repeatable,
    interactive,
    enabled,
  } = binding

  const shouldHandle = (
    enabled &&
    (!event.repeat || repeatable) &&
    (interactive || !isInteractiveInput(getActiveElement()))
  )

  if (shouldHandle) {
    fn(event)
  }
}