import { store, type Tables } from '^/app/store/index'
import { useLiveQuery } from 'dexie-react-hooks'

const [SubjectStateContext_, useSubject] = createRequiredContext<SubjectState>('SubjectStateContext')

export const SubjectStateContext = SubjectStateContext_
export default useSubject

type InternalState = {
  subjectId: number
  sessionId: number
}

export type Subject = Tables.Subjects.Entry & {
  traits: Tables.Traits.Entry[]
  sessions: Tables.Sessions.Entry[]
}

export type SubjectState = {
  isLoading: boolean
  subjectId: number
  sessionId: number
  setState: (nextState: { subjectId: number, sessionId?: number }) => void
  session: Tables.Sessions.Entry | null
  subject: Subject | null
  subjects: Subject[]
  traits: Tables.Traits.Entry[]
  sessions: Tables.Sessions.Entry[]
}

export function SubjectStateProvider({ children }: { children: React.ReactNode }) {
  const [internalState, setInternalState] = useState<InternalState>(() => {
    const value = !import.meta.env.SSR && localStorage.getItem('ids')

    return value ?
      JSON.parse(value) as any :
      { subjectId: 1, sessionId: 1 }
  })

  const { subjectId, sessionId } = internalState
  const allSubjects = useLiveQuery(() => store.subjects.toArray(), Array._, Array._)
  const allTraits = useLiveQuery(() => store.traits.toArray(), Array._, Array._)
  const allSessions = useLiveQuery(() => store.sessions.toArray(), Array._, Array._)

  const setState = useCallback((next: { subjectId: number, sessionId?: number }) => {
    const subject = allSubjects.find(s => s.id === next.subjectId)

    if (!subject) {
      console.warn(`No subject with ID '${next.subjectId}'`)
      return
    }

    const sessions_ = allSessions.filter(s => s.subjectId === subject.id)
    let session = sessions_.maxBy('id')

    if (next.sessionId) {
      const session_ = sessions_.find(s => s.id === next.sessionId)

      if (session_) {
        session = session_

      } else {
        console.warn(`No session with ID '${next.sessionId}'`)
      }
    }

    const nextState = {
      subjectId: subject.id,
      sessionId: session?.id ?? 0,
    }

    localStorage.setItem('ids', JSON.stringify(nextState))
    startTransition(() => setInternalState(nextState))
  }, [allSessions, allSubjects])



  const state = useMemo(() => {
    const isLoading = allSubjects === Array._ || allTraits === Array._ || allSessions === Array._

    const subjects = allSubjects.map(s => ({
      ...s,
      traits: allTraits.filter(t => t.subjectId === s.id),
      sessions: allSessions.filter(t => t.subjectId === s.id),
    }))

    const subject = subjects.find(s => s.id === subjectId) ?? null
    const session = allSessions.find(s => s.subjectId === subjectId && s.id === sessionId) ?? null

    return {
      sessionId,
      subjectId,
      isLoading,
      setState,
      subject,
      session,
      subjects,
      traits: allTraits.filter(t => t.subjectId === subjectId),
      sessions: allSessions.filter(t => t.subjectId === subjectId),
    }
  }, [
    allTraits,
    allSessions,
    allSubjects,
    setState,
    subjectId,
    sessionId,
  ])

  return (
    <SubjectStateContext.Provider value={state}>
      {children}
    </SubjectStateContext.Provider>
  )
}