import { Dexie } from 'dexie'

import type {
  Tables,
} from './types.js'

export class Store extends Dexie {
  assessments!: Tables.Assessments.Table
  breadcrumbs!: Tables.Breadcrumbs.Table
  sessions!: Tables.Sessions.Table
  subjects!: Tables.Subjects.Table
  threads!: Tables.Threads.Table
  traits!: Tables.Traits.Table

  constructor() {
    super('dx')
    this.version(7).stores({
      assessments: 'id,[id+subjectId+sessionId]',
      breadcrumbs: 'key,group,time',
      sessions: '++id',
      subjects: '++id',
      threads: 'id,[id+hash]',
      traits: 'id,subjectId',
    })
    .upgrade(t => Promise.all([
      t.table('assessments').clear(),
      t.table('breadcrumbs').clear(),
      t.table('sessions').clear(),
      t.table('subjects').clear(),
      t.table('threads').clear(),
      t.table('traits').clear(),
    ]))
  }
}

export default new Store()
