import '../pages/styles.css'
import React from 'react'

// import '../environment'
import { HotKeyProvider } from '#/hooks/useHotKey'
import { BreadcrumbsProvider } from '#/hooks/useBreadcrumbs'
import { SubjectStateProvider } from '#/hooks/useSubjectState'
import Developer from '@/developer/Developer'
import ModalBackdrop from '@/common/ModalBackdrop'
import ErrorBoundary from '@/common/ErrorBoundary'
import AuthProvider from '@/auth/AuthProvider'
import FlagProvider from './developer/FlagProvider.js'

export default function App({ children }: Readonly<{ children: React.ReactNode }>) {
  return (
    <>
      <AuthProvider>
        <FlagProvider>
          <HotKeyProvider>
            <SubjectStateProvider>
              <BreadcrumbsProvider>
                {children}

                <ErrorBoundary>
                  <Developer />
                </ErrorBoundary>
              </BreadcrumbsProvider>
            </SubjectStateProvider>
          </HotKeyProvider>
        </FlagProvider>
      </AuthProvider>
        
      <ModalBackdrop />
    </>
  )
}
