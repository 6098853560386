import { ClerkProvider } from '@clerk/clerk-react'


type AuthProviderProps = {
  children: React.ReactNode
}


const localization = {
  // formFieldLabel__emailAddress: 'Email',
  formButtonPrimary: 'Sign In',
}

export default function AuthProvider(props: AuthProviderProps) {
  const {
    children,
  } = props

  return (
    <ClerkProvider
      localization={localization}
      publishableKey={__clerkKey__}>
      {children}
    </ClerkProvider>
  )
}