import styles from './Modal.module.css'

export default styled.div({
  className: [styles.backdrop, `
    fixed
    inset-0
    bg-black
    z-100
    opacity-0
    transition-opacity
    pointer-events-none
  `],
  'aria-hidden': 'true',
})