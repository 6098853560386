import loader from '#/loader'
import useInDevMode from './hooks/useInDevMode.js'
import useDevConfig from './hooks/useDevConfig.js'

const DeveloperDrawer = lazyLoad(() => import('./DeveloperDrawer.js'))

export default function Developer() {
  const inDevMode = useInDevMode()
  const update = useDevConfig(s => s.update)

  useEffect(() => {
    if (inDevMode) {
      const [load] = loader<EmptyObject, Dx.Api.Dev.Config.Out>('/api/dev/config', { method: 'get' })

      load()
        .then(update)
        .catch(dx.capture)

    } else {
      update({})
    }
  }, [inDevMode, update])

  if (inDevMode) {
    return (
      <DeveloperDrawer />
    )
  }
}