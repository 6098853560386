import {
  hasAncestorMatching,
} from './traversal.js'

let _isTouchCapable: boolean | null = null
let _isMobileDevice: boolean | null = null


export function isTouchCapable() {
  if (_isTouchCapable != null) {
    return _isTouchCapable
  }

  try {
    document.createEvent('TouchEvent')
    return _isTouchCapable = true

  } catch (e) {
    return _isTouchCapable = false
  }
}


const mobilePattern = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i

export function isMobileDevice() {
  if (_isMobileDevice != null) {
    return _isMobileDevice
  }

  try {
    return _isMobileDevice = mobilePattern.test(window.navigator.userAgent)

  } catch (e) {
    return _isMobileDevice = false
  }
}

const interactiveSelector = [
  'a',
  'area',
  'audio',
  'button',
  'canvas',
  'datalist',
  'details',
  'dialog',
  'embed',
  'iframe',
  'input',
  'map',
  'object',
  'select',
  'summary',
  'textarea',
  'track',
  'video',
  '[data-interactive]',
  '[data-popover-trigger~="click"',
].join(', ')

const interactiveInputSelector = [
  'audio',
  'datalist',
  'embed',
  'iframe',
  'input',
  'select',
  'textarea',
  'track',
  'video',
].join(', ')

const iteractiveCache = new WeakMap()
const iteractiveInputCache = new WeakMap()

export function isInteractive(node: HTMLElement | null) {
  return node && iteractiveCache.ensure(node, () => hasAncestorMatching(node, interactiveSelector))
}

export function isInteractiveInput(node: HTMLElement | null) {
  return node && iteractiveInputCache.ensure(node, () => hasAncestorMatching(node, interactiveInputSelector))
}