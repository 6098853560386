import { store, type Tables } from '^/app/store/index'
import { useLiveQuery } from 'dexie-react-hooks'
import useSubject from './useSubjectState.js'
import { groups as searchGroups } from '@/search/config'


type BreadcrumbsReadContext = {
  isLoading: boolean
  breadcrumbs: Tables.Breadcrumbs.Entry[]
}

type BreadcrumbsWriteContext = {
  clear: () => Promise<void>
  add: (breadcrumb: Merge<Breadcrumb, { group: string }>) => Promise<void>
}

export const [BreadcrumbReadContext, useBreadcrumbReadContext] = createRequiredContext<BreadcrumbsReadContext>('BreadcrumbsReadContext')
export const [BreadcrumbWriteContext, useBreadcrumbs] = createRequiredContext<BreadcrumbsWriteContext>('BreadcrumbsWriteContext')

export default useBreadcrumbs

type Breadcrumb = SetOptional<
  Omit<Tables.Breadcrumbs.Entry, 'time'>,
  'sessionId' | 'subjectId' | 'url'
> & {
  params?: Record<string, any>
}

type BreadcrumbGroup = Record<Tables.Breadcrumbs.Entry['group'], {
  max: number
  expires: number
}>


const groups = {
  symptoms: {
    max: 1,
    expires: 7 * 24 * 60 * 60 * 1_000,
  },
  assessment: {
    max: 3,
    expires: 7 * 24 * 60 * 60 * 1_000,
  },
  condition: {
    max: 6,
    expires: 3 * 24 * 60 * 60 * 1_000,
  },
} satisfies BreadcrumbGroup
     


export function BreadcrumbsProvider({ children }: { children: React.ReactNode }) {
  const { sessionId, subjectId } = useSubject()
  const timers = useTimers()

  const breadcrumbs = useLiveQuery(() => store.breadcrumbs
    .orderBy('time')
    .reverse()
    .toArray(), Array._, Array._)


  const grouped = useMemoObject(() => breadcrumbs.groupedEntries('group'), [breadcrumbs])
  const isLoading = breadcrumbs === Array._
  const ref = useRef(grouped)
  ref.current = grouped



  const cleanup = useCallback(async () => {
    const deleteKeys = new Set<string>()

    ref.current.forEach(([group, items]) => {
      if (groups[group]) {
        const { max, expires } = groups[group]
        const at = Date.now() - expires

        items.forEach((item, index) => {
          if (index >= max || item.time < at) {
            deleteKeys.add(item.key)
          }
        })

      } else {
        items.forEach(item => {
          deleteKeys.add(item.key)
        })
      }
    })

    if (!deleteKeys.size) {
      return
    }

    await store
      .breadcrumbs
      .where('key')
      .anyOf([...deleteKeys])
      .delete()
  }, [])



  const writeContext = useMemo(() => {
    async function add(breadcrumb: Merge<Breadcrumb, { group: string }>): Promise<void>
    async function add(breadcrumb: Breadcrumb): Promise<void>
    async function add(breadcrumb: Merge<Breadcrumb, { group: string }>) {
      const {
        params,
        group,
        ...rest
      } = breadcrumb

      if (isValidGroup(group)) {
        await store.breadcrumbs.put({
          ...rest,
          group,
          time: Date.now(),
          sessionId: breadcrumb.sessionId ?? sessionId,
          subjectId: breadcrumb.subjectId ?? subjectId,
          url: dx.url(breadcrumb.url, params),
        })

        timers.name('cleanup').delay(cleanup, 2_000)
      }
    }

    return {
      add,
      clear() {
        return store.breadcrumbs.clear()
      },
    }
  }, [timers, sessionId, subjectId, cleanup])


  const readContext = useMemo(() => ({
    breadcrumbs: grouped.flatMap(([group, items]) => {
      if (!groups[group]) {
        return []
      }
      
      const { max, expires } = groups[group]
      const at = Date.now() - expires
      return items.filter((item, index) => index < max || item.time >= at)
    }),
    isLoading,
  }), [
    grouped,
    isLoading,
  ])

  return (
    <BreadcrumbWriteContext.Provider value={writeContext}>
      <BreadcrumbReadContext.Provider value={readContext}>
        {children}
      </BreadcrumbReadContext.Provider>
    </BreadcrumbWriteContext.Provider>
  )
}


function isValidGroup(name: string): name is Tables.Breadcrumbs.Entry['group'] {
  return Boolean(searchGroups[name as never])
}