import createStore, { conditionalStore } from '#/store'
import useInDevMode from './useInDevMode.js'

type DevConfigState = {
  config: Dx.Api.Dev.Config.Out
  update: (config: Dx.Api.Dev.Config.Out) => void
}

const store = createStore<DevConfigState>(set => ({
  config: {},
  update: config => set(s => s.config = config),
}))

export default conditionalStore(store, () => useInDevMode())